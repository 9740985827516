/**
 * A custom hook to manage the state of a screener.
 * @param object - a screener configuration object
 * @param object - an object containing site data
 * @param object - for localizing urls
 * @param function - a function to run when candidate form is complete
 * @param function - a function to run at each slide interaction
 */
import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { adjustSlideHeight, getCookie } from '../util/pagetools';
import Slide from './Slide';
import Safe from './Safe';
import CandidateForm from './FlexForm';
import LocationsForm from './LocationsForm';

export default function(config, sitedata, localizerfn, onFinish, onAdvance) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [visitedSlideIndex, setVisitedSlideIndex] = useState(0);
  const [isQualified, setQualified] = useState(false);
  const [candidateData, setCandidateData] = useState({});

  const cfg = initConfig(config);
  const {affirm, deny} = cfg.questionLabel;
  const formProps = {
    config: sitedata.form || {},
    onSubmit: onFinish || function() {console.log('collector()');},
    theme: (isQualified ? cfg.successResult.theme : cfg.failResult.theme),
    btnlabel: sitedata.form.button[isQualified ? 'label': 'labelDq'],
    isQualified
  };

  /**
   * Convenience function to switch to a given slide by its index.
   * @param int - the index of a slide
   * @return none
   */
  function nextSlide(slideIndex) {
    if (slideIndex===null || slideIndex===undefined) {
      setActiveSlideIndex(activeSlideIndex+1);
      setVisitedSlideIndex(activeSlideIndex+1);
    } else {
      setActiveSlideIndex(slideIndex);
      setVisitedSlideIndex(slideIndex);
    }
  }

  /**
   * High order function to provide an event handler
   * @param object - a question configuration object
   * @param boolean - a boolean user response
   * @return function
   */
  function advancer(question, slideReply) {
    return function(event) {
      if (onAdvance) onAdvance(question, slideReply);
      else console.log('A slide has been processed; where to go from here?');
    };
  }

  /**
   * Convenience method to add one or more fields to the candidate data.
   * @param object - fields to append to or overwrite candidate data
   */
  function appendToPayload(obj) {
    setCandidateData(Object.assign(candidateData, obj));
    return candidateData;
  }

  /**
   * Send candidate information to a specified destination.
   * @param object - the candidate information
   */
  function transmitData() {
    if (process.env.GATSBY_NO_BACKEND) {
      console.log('Backend disabled; payload=', candidateData);
      return;
    }
    const endpoint = '/.netlify/functions/screenerResults';
    fetch(endpoint, {
      method: 'POST',
      'Content-Type': 'application/json',
      body: JSON.stringify(candidateData)
    }).then(resp => { return resp.json(); }).then(data => {}).catch(error => {
      console.log(error);
      navigate(localizerfn('/generalError/'), {state: {description: error.message}});
    });
  }

  /**
   * Navigate to the conclusion page specifying qualification of candidate.
   * @param boolean - has a location been selected
   */
  function navigateToConclusion(locationSelected) {
    const {sitename, siteurl, homelink, footer, sections, cookie } = sitedata;
    const pageProps = {
      header: '',
      text: [''],
      isQualified,
      sitedata: {
        sitename,
        siteurl,
        homelink,
        footer,
        cookie,
        sections: sections.map(sec => ({title:sec.title, linkto:sec.linkto}))
      }
    }
    if (isQualified) {
      const success = cfg.successResult.thankyou;
      const locationOption = (locationSelected ? 'locationpicked' : 'nolocation');
      pageProps.header = success[locationOption].header;
      pageProps.text = success[locationOption].text;
      pageProps.theme = success[locationOption].theme;
      pageProps.btntheme = success.btntheme;
    } else {
      const fail = cfg.failResult.thankyou;
      pageProps.header = fail.header;
      pageProps.text = fail.text;
      pageProps.theme = fail.theme;
      pageProps.btntheme = fail.btntheme;
    }
    navigate(localizerfn('/thankyou/'), {state: pageProps});
  }

  function getLocation() {
    return { lat: candidateData.lat, lng: candidateData.lng, country: candidateData.country };
  }

  function setLocation(obj) {
    const Data2 = (obj ? obj.name : null);
    appendToPayload({Data2});
    transmitData();
    navigateToConclusion(Boolean(Data2));
  }

  function render() {
    return (
      <>
        {cfg.questions.map((item,idx) => (
          <Slide key={`q-${idx}`}
                 question={item}
                 btnLabels={{affirm, deny}}
                 btnTheme={cfg.btntheme}
                 isActive={activeSlideIndex===item.seq}
                 isPast={visitedSlideIndex>=item.seq}
                 responder={advancer}
          />
        ))}
        {cfg.pages.map((item, idx) => {
          if (item.id==='locations') {
            return (
              <Slide key={item.id}
                     isActive={(activeSlideIndex===cfg[item.id])}
                     isPast={visitedSlideIndex>=cfg[item.id]}
                     className="bg-locations"
                     >
                <Safe className="slide-h1" type="h1" content={item.header} />
                <Safe className="slide-h2" type="h2" content={item.message} />
                <LocationsForm patientLoc={getLocation()} locations={cfg.sitelocations} setLocation={setLocation} />
              </Slide>
            );
          }
          const showform = (item.id==='success' && isQualified) || (item.id==='fail' && !isQualified);
          return (
            <Slide key={item.id}
                   isActive={(activeSlideIndex===cfg[item.id])}
                   isPast={visitedSlideIndex>=cfg[item.id]}>
              <Safe className="slide-h1" type="h1" content={item.header} />
              <Safe className="slide-h2" type="h2" content={item.message} />
              {showform && <CandidateForm {...formProps} />}
            </Slide>
          );
        })}
      </>
    );
  }

  useEffect(() => {
    function watchResize() { window.addEventListener('resize', adjustSlideHeight); }
    watchResize();
    return () => { window.removeEventListener('resize', adjustSlideHeight); };
  });

  return {
    config: function() {return cfg;},
    isQ: function() {return isQualified;},
    setQualified,
    nextSlide,
    appendToPayload,
    transmitData,
    navigateToConclusion,
    render
  };
};

function initConfig(config) {
  const newConfig = Object.assign({}, config);
  if (!Array.isArray(newConfig.questions)) newConfig.questions = [];

  const qCount = newConfig.questions.length;
  newConfig.lastQuestionIndex = qCount-1;
  // Add indexes for "post-question" slides: success, location picker, fail
  for (let i=0; i<newConfig.pages.length; i++) { newConfig[`${newConfig.pages[i].id}`]=qCount+i; }

  // Add a "proceed" function and other attributes to each question
  newConfig.questions = newConfig.questions.map((q, index) => ({
    ...q,
    seq: index, // in case questions are NOT the first slides
    id: index,
    label: makeQuestionLabel(index+1, newConfig.questionLabel, qCount),
    proceed: getFn(q.proceedIfYes),
    isLast: function(){ return (index>=newConfig.lastQuestionIndex); }
  }));
  return newConfig;
}

/**
 * Generate a counter label for a qiven question.
 * @param string - a configured alias to replace default numeric index
 * @param object - an object containing localized words
 * @param int - a number representing total questions
 * @return string
 */
function makeQuestionLabel(alias, labels, count) { return `${labels.noun} ${alias} ${labels.prep} ${count}`; }

/**
 * Return logic (another function) determining correctness of question response.
 * @param boolean - a configuration setting indicating whether or not Yes=Correct
 * @return function
 */
function getFn(proceedIfYes) {
  if (proceedIfYes===null) return function(reply) {return true};
  return (proceedIfYes ? fnYesGood : fnNoGood);
}

/**
 * Implement Yes=Correct logic.
 * @param boolean - a user response to a question
 * @return boolean - whether or not response was correct
 */
function fnYesGood(reply) { return Boolean(reply); }

/**
 * Implement No=Correct logic.
 * @param boolean - a user response to a question
 * @return boolean - whether or not response was correct
 */
function fnNoGood(reply) { return !Boolean(reply); }

